import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ProductType } from 'Type/ProductList.type';
import { isSignedIn } from 'Util/Auth';
import {
    getPrice
} from 'Util/Product/Extract';

import { B2B_ACCOUNTS_ID } from '../MyAccountCustomerTable/MyAccountCustomerTable.config';

/** @namespace Bodypwa/Component/CampaignLabel/Component */
export class CampaignLabelComponent extends PureComponent {
    static propTypes = {
        product: ProductType,
        discount: PropTypes.number,
        isCampaingLabel: PropTypes.bool,
        isBottom: PropTypes.bool,
        isAbsolute: PropTypes.bool,
        group_id: PropTypes.number
    };

    static defaultProps = {
        product: {},
        isCampaingLabel: true,
        discount: 0,
        isBottom: false,
        isAbsolute: true,
        group_id: 0
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getLabelValue() {
        const {
            product: {
                price_range: priceRange,
                dynamic_price: dynamicPrice,
                type,
                attributes
            } = {}
        } = this.props;

        const {
            campaign_label: {
                attribute_value, attribute_options
            } = {}
        } = attributes || {};
        const { price: { discount: { percentOff } } } = getPrice(priceRange, dynamicPrice, {}, type);
        const value = percentOff;

        const campaignLabel = attribute_value ? attribute_options[attribute_value].label : '';
        if (!value && !campaignLabel) {
            return null;
        }

        return campaignLabel || `-${Math.round(value)}%`;
    }

    renderCampaingLabel() {
        const { isBottom, isAbsolute } = this.props;
        const label = this.getLabelValue();
        if (!label) {
            return null;
        }

        return (
            <div
              block="ProductCard"
              elem="SaleLabel"
              mods={ { isBottom, isAbsolute } }
            >
                { label }
            </div>
        );
    }

    renderDiscountLabel() {
        const { discount } = this.props;

        if (discount === 0) {
            return null;
        }

        return (
            <span
              block="ProductPrice"
              elem="PriceDiscount"
            >
                   { __('You save %s', `${Math.round(discount)}%`) }
            </span>
        );
    }

    render() {
        const { isCampaingLabel, group_id } = this.props;
        const isB2bCustomer = isSignedIn() && B2B_ACCOUNTS_ID.includes(group_id);
        if (isB2bCustomer) {
            return null;
        }

        return isCampaingLabel ? this.renderCampaingLabel() : this.renderDiscountLabel();
    }
}

export default CampaignLabelComponent;
