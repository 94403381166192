import Html from 'Component/Html';
import { promotions_top_block } from 'Component/PromotionsTopBlock/PromotionsTopBlock.config';
import {
    CmsBlock as SourceCmsBlock
} from 'SourceComponent/CmsBlock/CmsBlock.component';

import './CmsBlock.style';
import './CmsBlock.override.style';
/** @namespace Bodypwa/Component/CmsBlock/Component */
export class CmsBlockComponent extends SourceCmsBlock {
    renderCmsPlaceholder() {
        return (
            <div block="Placehoolder" elem="Container">
                <div block="Placehoolder" elem="InnerContainer">
                    <div block="Placehoolder" elem="SectionPlaceholder" mods={ { promotions_top_block: true } } />
                </div>
            </div>
        );
    }

    render() {
        const {
            cmsBlock: {
                identifier,
                content,
                disabled
            },
            blockType,
            id
        } = this.props;

        // TODO MAKE COMPONENT TO HANDLE CMS BLOCK PRELOADERS
        if (id === promotions_top_block && !content) {
            return this.renderCmsPlaceholder();
        }

        if (disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        return (
            <div
              block="CmsBlock"
              elem="Wrapper"
              mods={ { type: blockType } }
            >
                <Html content={ content } />
            </div>
        );
    }
}

export default CmsBlockComponent;
